#settings {
  h1 {
    font-size: 2rem;
  }

  header {
    $iconWidth: 64px;

    text-align: center;
    img {
      width: $iconWidth;
    }
    h1 {
      margin-top: 10px;
    }

    @media (min-width: 290px) {
      @supports (display: grid) {
        text-align: left;
        display: grid;
        grid-template-columns: $iconWidth 1fr;
        grid-gap: 1rem;
        h1 {
          margin-top: 0;
          line-height: $iconWidth;
        }
      }
    }
  }

  .ui.form {
    > .accordion {
      > .title {
        padding-left: .75em;
        transition: all .2s;
        position: relative;
        padding-left: 2.5rem;
        color: rgba(#000, .6);
        &:hover {
          color: #078;
        }
        .icon {
          margin-right: .75em;
          // transition: all .2s;
          position: absolute;
          left: 10px;
          top: 14px;
        }

        &.active {
          font-size: 1.5rem;
          padding: 1rem;
          line-height: 1.2;
          color: #000;
          .icon {
            font-size: 1rem;
            // left: -100px;
            // color: #078;
            opacity: 0;
          }
        }
      } // end .title

      > .content {
        .field {
          margin-bottom: .3rem;
          > label {
            font-size: inherit;
            > p {
              margin-top: 1rem;
              margin-bottom: .5rem;
            }
            .field {
              font-weight: normal;
            }
          }
          &:first-child > label > p {
            margin-top: 0;
          }
        }

        .accordion {
          .content {
            margin: 0;
            padding: .5em 1em 1em;
          }
        }
      }
    }
  }
}