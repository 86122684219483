:root {
  --color-stava-rex-bg: #ff9;
  --color-stava-rex-text: #47b;

  --color-spellright-bg: #def;
  --color-spellright-text: #e44;

  --color-verityspell-bg: #f4f0ec;
  --color-verityspell-text: #000;

  --color-stavlet-bg: #fee;
  --color-stavlet-text: #c02;

  --color-schreibrex-bg: #fef;
  --color-schreibrex-text: #849;
}

body {
  overflow: hidden;
  min-width: 270px;
}

.ui.card, .ui.cards>.card {
  width: auto;
}

.tab-view-panel {
  .tab-view-panel-scrolling-content {
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 40px);
    padding-bottom: 1em;
  }
}

.ui.top.attached.message ~ .tab-view-panel {
  max-height: calc(100vh - 90px);
}

.ui.fluid.search .ui.input {
  width: 100%;
}

.schools-results {
  text-align: left;
  .ui.list {
    margin-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    &:empty {
      margin-top: 0;
    }
  }
}

.ui.selection.list > .item .ui.placeholder .paragraph:before {
  height: 10px;
}

a.ui.basic.inverted.button,
a.ui.basic.inverted.buttons .button {
  color: #eff !important;
}

.ui.basic.buttons a.button {
  padding: .78571429em 1.5em .78571429em;
  text-decoration: none;
}

.ui.form .warning.message:not(:empty) {
  display: block;
}

.ui.modal {
  &:not(.basic) {
    max-width: 320px;
  }
  &.center {
    text-align: center;
  }
  &.basic {
    h1.header {
      font-size: 2rem;
    }

    a, a:visited {
      color: #fff;
      text-decoration: underline;
      &:hover, &:active {
        text-decoration: none;
      }
    }
  }

  .header {
    
    img {
      width: 64px;
      margin: 0 auto .5em;
    }
  }

  .content {
    + .content {
      padding-top: 0 !important;
    }
    .accordion {
      text-align: left;
    }
  }

  .actions {
    overflow: hidden;
    p {
      text-align: center;
    }
    > p {
      padding-bottom: 1rem;
      margin-top: -.5rem;
    }
    + .content {
      border-top: 1px solid rgba(34,36,38,.15);
    }
    .form {
      padding-bottom: 1rem;
      .error.message:not(:empty) {
        display: block;
      }
    }
  }
  
  .search .results {
    max-height: 40vh;
    overflow-y: auto;
  }
}


@mixin brandedModalHeader($background, $color) {
  .ui.modal .header.branded {
    background: $background;
    color: $color;
  }
}

[data-app="stava-rex"] {
  @include brandedModalHeader(var(--color-stava-rex-bg), var(--color-stava-rex-text));
}
[data-app="spellright"] {
  @include brandedModalHeader(var(--color-spellright-bg), var(--color-spellright-text));
}
[data-app="verityspell"] {
  @include brandedModalHeader(var(--color-verityspell-bg), var(--color-verityspell-text));
}
[data-app="stavlet"] {
  @include brandedModalHeader(var(--color-stavlet-bg), var(--color-stavlet-text));
}
[data-app="schreibrex"] {
  @include brandedModalHeader(var(--color-schreibrex-bg), var(--color-schreibrex-text));
}